@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?56944506#fontello') format('svg');
  }
}
*/
 
 [class^="ajjas-"]:before, [class*=" ajjas-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.ajjas-link:before { content: '\e800'; } /* '' */
.ajjas-tick:before { content: '\e801'; } /* '' */
.ajjas-stats:before { content: '\e802'; } /* '' */
.ajjas-error:before { content: '\e803'; } /* '' */
.ajjas-bikeicon:before { content: '\e804'; } /* '' */
.ajjas-arrbigleft:before { content: '\e805'; } /* '' */
.ajjas-up:before { content: '\e806'; } /* '' */
.ajjas-tickbox:before { content: '\e807'; } /* '' */
.ajjas-phone:before { content: '\e808'; } /* '' */
.ajjas-setting:before { content: '\e809'; } /* '' */
.ajjas-speedometer:before { content: '\e80a'; } /* '' */
.ajjas-bell:before { content: '\e80b'; } /* '' */
.ajjas-rides:before { content: '\e80c'; } /* '' */
.ajjas-appsetting:before { content: '\e80d'; } /* '' */
.ajjas-untick:before { content: '\e80e'; } /* '' */
.ajjas-info:before { content: '\e80f'; } /* '' */
.ajjas-delete:before { content: '\e810'; } /* '' */
.ajjas-meter:before { content: '\e811'; } /* '' */
.ajjas-more:before { content: '\e812'; } /* '' */
.ajjas-leanangle:before { content: '\e813'; } /* '' */
.ajjas-pending:before { content: '\e814'; } /* '' */
.ajjas-unlock:before { content: '\e815'; } /* '' */
.ajjas-lock:before { content: '\e816'; } /* '' */
.ajjas-appnotif:before { content: '\e817'; } /* '' */
.ajjas-satellite:before { content: '\e818'; } /* '' */
.ajjas-qrscan:before { content: '\e819'; } /* '' */
.ajjas-emeregencycontact:before { content: '\e81a'; } /* '' */
.ajjas-help:before { content: '\e81b'; } /* '' */
.ajjas-bt:before { content: '\e81c'; } /* '' */
.ajjas-menu:before { content: '\e81d'; } /* '' */
.ajjas-sharebyme:before { content: '\e81e'; } /* '' */
.ajjas-sms:before { content: '\e81f'; } /* '' */
.ajjas-sharewithme:before { content: '\e820'; } /* '' */
.ajjas-attention:before { content: '\e821'; } /* '' */
.ajjas-mapfocus:before { content: '\e822'; } /* '' */
.ajjas-startshare:before { content: '\e823'; } /* '' */
.ajjas-stopshare:before { content: '\e824'; } /* '' */
.ajjas-bikeshare:before { content: '\e825'; } /* '' */
.ajjas-oil:before { content: '\e826'; } /* '' */
.ajjas-caricon:before { content: '\e827'; } /* '' */
.ajjas-adddevice:before { content: '\e828'; } /* '' */
.ajjas-petrol-pump:before { content: '\e829'; } /* '' */
.ajjas-right:before { content: '\e82a'; } /* '' */
.ajjas-flashon:before { content: '\e82b'; } /* '' */
.ajjas-flashoff:before { content: '\e82c'; } /* '' */
.ajjas-mapsett:before { content: '\e82d'; } /* '' */
.ajjas-arrleft:before { content: '\e82e'; } /* '' */
.ajjas-left:before { content: '\e82f'; } /* '' */
.ajjas-arrbigright:before { content: '\e830'; } /* '' */
.ajjas-edit:before { content: '\e831'; } /* '' */
.ajjas-calendar:before { content: '\e832'; } /* '' */
.ajjas-circletick:before { content: '\e833'; } /* '' */
.ajjas-loadspin:before { content: '\e834'; } /* '' */
.ajjas-circleuntick:before { content: '\e835'; } /* '' */
.ajjas-fuel:before { content: '\e836'; } /* '' */
.ajjas-expand-collapse-arrow:before { content: '\e837'; } /* '' */
.ajjas-distance:before { content: '\e838'; } /* '' */
.ajjas-cash:before { content: '\e839'; } /* '' */
.ajjas-radio-off:before { content: '\e83a'; } /* '' */
.ajjas-radio-on:before { content: '\e83b'; } /* '' */
.ajjas-session:before { content: '\e83c'; } /* '' */
.ajjas-tank-capacity:before { content: '\e83d'; } /* '' */
.ajjas-reachbike:before { content: '\e83e'; } /* '' */
.ajjas-traffic:before { content: '\e83f'; } /* '' */
.ajjas-cancel:before { content: '\e840'; } /* '' */
.ajjas-truckicon:before { content: '\e841'; } /* '' */
.ajjas-startstop:before { content: '\e842'; } /* '' */
.ajjas-staticon:before { content: '\e843'; } /* '' */
.ajjas-autoicon:before { content: '\e844'; } /* '' */
.ajjas-autobgicon:before { content: '\e845'; } /* '' */
.ajjas-bikebgicon:before { content: '\e846'; } /* '' */
.ajjas-carbgicon:before { content: '\e847'; } /* '' */
.ajjas-truckbgicon:before { content: '\e848'; } /* '' */
.ajjas-distance-1:before { content: '\e849'; } /* '' */
.ajjas-stopwatch:before { content: '\e84a'; } /* '' */
.ajjas-topspeed:before { content: '\e84b'; } /* '' */
.ajjas-avgdis:before { content: '\e84c'; } /* '' */
.ajjas-avgspeed:before { content: '\e84d'; } /* '' */
.ajjas-hooray:before { content: '\e84e'; } /* '' */
.ajjas-tick-1:before { content: '\e84f'; } /* '' */
.ajjas-arrright:before { content: '\e850'; } /* '' */
.ajjas-dropdown:before { content: '\e851'; } /* '' */
.ajjas-plan:before { content: '\e852'; } /* '' */
.ajjas-notisetting:before { content: '\e853'; } /* '' */
.ajjas-vehicle:before { content: '\e854'; } /* '' */
.ajjas-profile:before { content: '\e855'; } /* '' */
.ajjas-language:before { content: '\e856'; } /* '' */
.ajjas-oilnew:before { content: '\e857'; } /* '' */
.ajjas-logout:before { content: '\e858'; } /* '' */
.ajjas-pwvisible:before { content: '\e859'; } /* '' */
.ajjas-pwhidden:before { content: '\e85a'; } /* '' */
.ajjas-thefticon:before { content: '\e85b'; } /* '' */
.ajjas-accidentalert:before { content: '\e85c'; } /* '' */
.ajjas-bikelocicon:before { content: '\e85d'; } /* '' */
.ajjas-carlocicon:before { content: '\e85e'; } /* '' */
.ajjas-cloud:before { content: '\e85f'; } /* '' */
.ajjas-riderecord:before { content: '\e860'; } /* '' */
.ajjas-rideshare:before { content: '\e861'; } /* '' */
.ajjas-nointerneticon:before { content: '\e862'; } /* '' */
.ajjas-arrsmallright:before { content: '\e863'; } /* '' */
.ajjas-arrsmallleft:before { content: '\e864'; } /* '' */
.ajjas-service:before { content: '\e865'; } /* '' */
.ajjas-cromenotif:before { content: '\e866'; } /* '' */
.ajjas-redirect:before { content: '\e867'; } /* '' */
.ajjas-alert:before { content: '\e868'; } /* '' */
.ajjas-odometer:before { content: '\e869'; } /* '' */
.ajjas-time:before { content: '\e86a'; } /* '' */
.ajjas-plus:before { content: '\e86b'; } /* '' */
.ajjas-ride-share-image:before { content: '\e86c'; } /* '' */
.ajjas-walk:before { content: '\e86d'; } /* '' */
.ajjas-ride-share:before { content: '\e86e'; } /* '' */
.ajjas-like:before { content: '\e86f'; } /* '' */
.ajjas-heart:before { content: '\e870'; } /* '' */
.ajjas-dislike:before { content: '\e871'; } /* '' */
.ajjas-order:before { content: '\e872'; } /* '' */
.ajjas-rupee:before { content: '\e873'; } /* '' */
.ajjas-route:before { content: '\e874'; } /* '' */
.ajjas-geo-fence:before { content: '\e875'; } /* '' */
.ajjas-speed-alert:before { content: '\e876'; } /* '' */
.ajjas-cstopwatch:before { content: '\e877'; } /* '' */
.ajjas-ctopspeed:before { content: '\e878'; } /* '' */
.ajjas-coil:before { content: '\e879'; } /* '' */
.ajjas-cdistance-1:before { content: '\e87a'; } /* '' */
.ajjas-cavgspeed:before { content: '\e87b'; } /* '' */
.ajjas-csv-download:before { content: '\e87c'; } /* '' */
.ajjas-langle:before { content: '\e87d'; } /* '' */
.ajjas-rec:before { content: '\e87e'; } /* '' */
.ajjas-other:before { content: '\e87f'; } /* '' */
.ajjas-pause:before { content: '\e880'; } /* '' */
.ajjas-stop:before { content: '\e881'; } /* '' */
.ajjas-emergency:before { content: '\e882'; } /* '' */
.ajjas-play:before { content: '\e883'; } /* '' */
.ajjas-ajjas:before { content: '\e884'; } /* '' */
.ajjas-stopwatch-1:before { content: '\e885'; } /* '' */
.ajjas-blankstar:before { content: '\e886'; } /* '' */
.ajjas-filledstar:before { content: '\e887'; } /* '' */
.ajjas-ajjas-1:before { content: '\e888'; } /* '' */
.ajjas-distance-2:before { content: '\e889'; } /* '' */
.ajjas-avgspeed-1:before { content: '\e88a'; } /* '' */
.ajjas-email-verify:before { content: '\e88b'; } /* '' */
.ajjas-email-succ:before { content: '\e88c'; } /* '' */
.ajjas-ride-download:before { content: '\e88d'; } /* '' */
.ajjas-loader:before { content: '\e88e'; } /* '' */
.ajjas-search:before { content: '\e88f'; } /* '' */
.ajjas-tick-comp:before { content: '\e890'; } /* '' */
.ajjas-contact:before { content: '\e891'; } /* '' */
.ajjas-email:before { content: '\e892'; } /* '' */
.ajjas-profile-1:before { content: '\e893'; } /* '' */
.ajjas-blood:before { content: '\e894'; } /* '' */
.ajjas-insurance:before { content: '\e895'; } /* '' */
.ajjas-add:before { content: '\e896'; } /* '' */
.ajjas-android:before { content: '\e897'; } /* '' */
.ajjas-browser-window:before { content: '\e89c'; } /* '' */
.ajjas-refresh:before { content: '\f025'; } /* '' */
.ajjas-mail:before { content: '\f0e0'; } /* '' */
.ajjas-apple:before { content: '\f179'; } /* '' */
.ajjas-whatsapp:before { content: '\f232'; } /* '' */