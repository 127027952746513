@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

.ajjas-logo { display:inline-block; background:url(../images/logo-lat.png) no-repeat; height:20%; margin: -8px 15px 0 0%; padding:0; background-size: contain; }
.leftfloat { float: left; }

.cs{
  min-height: 680px;
}
.margin12{
  margin: 12px;
}
.fontlarge{
  font-size: xx-large;
}
input {
  outline: none;
  border: none;
}


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body, html {
  height: 100%;
}


input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

.margin0{
  margin: 0px;
}
.margin-right10{
  margin-right: 10px;
}
.margin-bottom15{
  margin-bottom: 15px;
}
.margin-bottom30{
  margin-bottom: 30px;
}
.margin-left10{
  margin-left: 10px;
}
.margin-left15{
  margin-left: 15px;
}
/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login100 {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 77px 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}
.login100-form-title i {
  font-size: 60px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 37px;
}

.input100 {
  font-size: 15px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::after {
  font-size: 15px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -15px;
}
.input100:visited + .focus-input100::after {
  top: -15px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -15px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: rgb(0, 148, 222);
}

.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}
.searchlabel{
  font-size: 13px;
  color: #666;
  font-weight: 400;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}
.online-offline{
  border-radius:50%;
  display: inline-block;
  height: 6px;
  margin-left: 4px;
  margin-right: 3px;
  margin-bottom: 1px;
  width: 6px;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-arrow-left:before {
  content: "\e091";
}
.glyphicon-arrow-right:before {
  content: "\e092";
}
.glyphicon-wrench:before {
  margin-right: .2em;
  margin-left: .2em;
}
.glyphicon-usd:before {
  margin-right: .2em;
  margin-left: .2em;
}
.form-control[readonly]{
  background-color: #fff !important;
  opacity: 1;
}

.tabs a {
  padding:5px 2px 9px 2px;
  font-size:13px;
  width: 33.3%;
  border-left:1px solid rgba(255, 255, 255, .2);
  text-align: center;
  /*color: #fff;*/
}
.tabs a:first-child {
  border-left: 0;
}

.trip-mast {
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(125,125,125,.2);
  -moz-box-shadow: 0px 0px 5px 1px rgba(125,125,125,.2);
  box-shadow: 0px 0px 5px 1px rgba(125,125,125,.2);
  /*background-color: #fff;*/
  margin-bottom: 15px;
}
.trip-details {
  padding:10px;
  width: calc(100% - 100px);
  border-radius: 0 5px 5px 0;
}
.flex {
  display: -webkit-flex; /* Safari */
    display: flex;
}

.ride {
  padding:10px 10px 0 10px;
  width: 100%;
  border-radius: 5px;
}
.ride .tabs {
  border-bottom: 0;
}
.span-tiles{
  padding:5px 2px 9px 2px;
  font-size:13px;
  width: 33.3%;
  border-left:1px solid rgba(255, 255, 255, .2);
  text-align: center;
}

.inner-container { background: #fff; border: 1px solid #d7dfe3; margin-bottom: 30px; }
.inner-container h3 { margin: 15px; }
.inner-container p { font-size: 14px; color: #333; font-weight: 600; }
.inner-container label { font-size: 13px; color: #666; font-weight: 400; }
.horz-line-bottom { border-bottom: 3px double #ddd; }

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 77px 15px 33px 15px;
  }
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-height: 600px){
  .cs{
    min-height: 500px;
  }
}
@media (max-height: 500px){
  .cs{
    min-height: 400px;
  }
}
@media (max-width: 1440px){
  .sharelink{
    margin-left: 15px;
  }
}
@media (max-width: 450px){
  .sharelink{
    display: block;
    padding-left: 0px;
    margin-top: 10px;
    width: 100% !important;
  }
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #6a7dfe;
  background: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  background: -o-linear-gradient(to right, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  background: linear-gradient(to right, #21d4fd, #b721ff);
}


.panel-success>.panel-heading {
  color: white !important;
  background-color: green !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}