.align-center { align-content : center}

.bottom5 { bottom: 5% }

.bottom-suc {
  height: 15%;
  width: 310px;
  font-family: Roboto;
}
.body{
  font-family: Roboto;
}
.border-none { border-style: none; }
.border-outline { border-outline: outline; }

.border-bottom {border-bottom:1px solid #000000;}
.border-bottom-gray {border-bottom:1px solid #9b9b9b;}
.border-gray { border:1px solid #e1e1e1;}

.backgroundColor-grey { background-color: #f7f7f7 }

.border-radius4 {border-radius:4px;}

/*color */
.color-red { color: red; }
.color-white { color: white; }
.color-9b9b9b { color: #9b9b9b !important; }
.color-4a4a4a { color: #4a4a4a; }
.color-0094de { color: #0094de !important; }
.color-ffffff { color: #ffffff; }
.color-green {color: #7ed321;   }
.color-grey { color:#4a4a4a; }
.color-e1e1e1 { color:#e1e1e1; }
.color-b5b5b5 {color: #b5b5b5;}
.color-7ac700 {color: #7ac700;}
.color-00a8e2 {color: #00a8e2;}


.display-inline { display: inline; }

/*font family */
.font-family-roboto { font-family: Roboto; }
.font-family-OpenSans { font-family: Open Sans; }

.widget-mylocation-button {
  background-color: rgba(255,255,255,1);
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: block;
  width: 37px;
  height: 37px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s ease-out;
}

.ripple-container {
  position: relative;
  overflow: hidden;

}

.GeoFenceTextBox{
  margin-left: 4%;
  font-weight: unset;
  margin-top: 1%;
  font-size: 12px
}

.widget-mylocation-button-icon-common {
  display: block;
  height: 18px;
  left: 10px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10px;
  width: 18px;
}
.widget-mylocation-button .widget-mylocation-button-normal {
  background-position: 0 0;
}

.widget-mylocation-button .widget-mylocation-button-blue {
  background-position: 18px 0;
}

.widget-mylocation-button .widget-mylocation-cookieless {
  background-image: url("../images/mylocation.png");
  background-size: 180px 18px;
}

.popup-bubble {
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
}

.popup-bubble-anchor {
  position: absolute;
  top: -13px;
  left: 0;
}

.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-40%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

/*font size */
.font-size11px { font-size: 11px }
.font-size12px { font-size: 12px }
.font-size13px { font-size: 13px }
.font-size14px { font-size: 14px }
.font-size16px { font-size: 16px }
.font-size18px { font-size: 18px }
.font-size15px { font-size: 15px }
.font-size16px { font-size: 16px }
.font-size20px { font-size: 20px }
.font-size22px { font-size: 22px }
.font-size25px { font-size: 25px }
.font-size22px { font-size: 22px }
.font-size30px { font-size: 30px }
.font-size40px { font-size: 40px }
.font-size50px { font-size: 50px }
.font-size-Medium { font-size: "medium"}

.font-style-normal { font-style: normal }

.float-right { float: right }
.float-left { float: left }

.font-bold { font-weight: bold }
.font-normal { font-weight: normal }
.font-weight600 { font-weight: 600 }
.font-weight400 { font-weight: 400 }

.height100vh { height: 100vh }

/*height in "%" */
.height10 { height: 10% }
.height15 { height: 15% }
.height20 {height: 20%;}
.height30 {height: 30%;}
.height40 {height: 40%;}
.height45 {height: 45%;}
.height100 {height: 100%;}

/*height in "px" */
.height30px { height: 30px }
.height48px { height: 48px }
.height50px { height: 50px }
.height260px { height: 260px }
.height450px { height: 450px }
.height480px { height: 480px }
.height-70vh { height : 70vh }
.height-75vh { height : 75vh }
.height-83vh { height : 83vh }

/*landing image*/
.landing-image {
  background: url('../images/landing-image-cmp.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: inline-block;
  width: 100vw;
}

.filter-disable { background-color: #f2f2f2 }

/*letter space*/
.letter-space {letter-spacing: 0.5px;}


/*line input*/
.line-input-title{
  height: 100%;
  color: #4a4a4a;
  font-size: 14px;
  padding-left: 10%;
  font-family: Roboto;
}

.line-input {
border-style: none;
border-outline: outline;
background-color: #f7f7f7;
border-bottom:1px solid #000000;
margin-left: 11%;
margin-top: 5% ;
width: 100%;
font-size: 13px;
 }

.line-input-error {
  color: red;
  font-size: 12px;
  margin-bottom: 10%;
  margin-left: 10%;
}



/*Margin left*/
.margin-left9px{ margin-left: 9px;}
.margin-left1{ margin-left: 1%;}
.margin-left2{ margin-left: 2%;}
.margin-left3{ margin-left: 3%;}
.margin-left4{ margin-left: 4%;}
.margin-left10{ margin-left: 10%;}
.margin-left11{ margin-left: 11%;}
.margin-left14{ margin-left: 14%;}
.margin-left15{ margin-left: 15%;}
.margin-left20{ margin-left: 20%;}
.margin-left25{ margin-left: 25%;}
.margin-left47{ margin-left: 47%;}

/*Margin right*/
.margin-right0{ margin-right: 0% }
.margin-right1{ margin-right: 1%;}
.margin-right2{ margin-right: 2%;}
.margin-right4{ margin-right: 4%;}
.margin-right5{ margin-right: 5%;}
.margin-right10{ margin-right: 10%;}

/*Margin top*/
.margin-top0 { margin-top: 0%}
.margin-top2 { margin-top: 2%}
.margin-top3 { margin-top: 3%}
.margin-top4 { margin-top: 4%}
.margin-top5 { margin-top: 5%}
.margin-top7 { margin-top: 7%}
.margin-top10{ margin-top: 10%;}
.margin-top20 { margin-top: 20% }
.margin-top25 { margin-top: 25% }
.margin-top50 { margin-top: 50% }
.margin-top55 { margin-top: 55% }

.margin-top20px{margin-top: 20px;}

/*Margin bottom*/
.margin-bottom0 { margin-bottom: 0%}
.margin-bottom1 { margin-bottom: 1%}
.margin-bottom2 { margin-bottom: 2%}
.margin-bottom7{ margin-bottom: 7%;}
.margin-bottom20{ margin-bottom: 20%;}
.margin-bottom{ margin-bottom: 6%;}
.margin-bottom20px{margin-bottom: 20px;}


/*position*/
.position-absolute{ position : absolute }
.position-initial{ position : initial }

/*position left*/
.padding-left0{ padding-left: 0%; }
.padding-left1{ padding-left: 1%; }
.padding-left2{ padding-left: 2%; }
.padding-left3{ padding-left: 3%; }
.padding-left5{ padding-left: 5%; }
.padding-left10{ padding-left: 10%; }
.padding-left20{ padding-left: 20%; }
.padding-left25{ padding-left: 25%; }
.padding-left30{ padding-left: 30%; }

/*position right*/
.padding-right0{ padding-right: 0%; }
.padding-right5{ padding-right: 5%; }
.padding-right8{ padding-right: 8%;}
.padding-right10{ padding-right: 10%; }
.padding-right25{ padding-right: 25%; }

.hurray-info{ margin-top: 35% ;}

.hurray-btn{ margin-top: 10%;}

.box {
  box-Shadow : 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  height: 50% ;
  width: 80%;
  align-self: center;
  background-color: "white"
}
.box-padding{
  padding-left: 17%;
}
.box-content{
  padding-left: 5%;
}
.vehicle-type{
  font-size: 10px;
}
.veh-form-title{
  font-size: 15px;
  padding-top: 1%
}

.form-padding{
  padding-top: 1%;
}

.login{
  background-color: #f7f7f7;
  width: 100%;
  height: 100%
}

/*position top*/
.padding-top1{ padding-top: 1%; }
.padding-top5{ padding-top: 5%; }
.padding-top10{ padding-top: 10%; }
.padding-top20{ padding-top: 20%; }
/*
  position: relative;
  max-width: 1200px;
  margin: 0 auto;*/

.left-timeline{
  border-left:3px solid #e3e3e3;
  padding-left:10px;
}
.left-timeline .timeline-item{
  margin-bottom : 15px;
}

.timeline-item::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#3498db;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-red::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#ff7c8c;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-grey::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#9b9b9b;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-green::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#8ddd39;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-yellow::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#efc200;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-orange::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#ffa484;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.timeline-item-blue::before{
  content :' ';
  display: inline-block;
  width:10px;
  height:10px;
  background-color:#00A8E2;
  transform : translateX(-19px) translateY(3px);
  border-radius:100%;
}

.notif {
  height: 60vh;
  margin-left:5%;
  overflow-y: auto;
  width:97%;
  margin-top:4%
}

.noNotif {
  margin-top: 5%
}

/*width in "%" */
.width100 { width: 100% }
.width12 { width: 12%; }
.width30 { width: 30%; }
.width35 { width: 35%; }
.width60 { width: 60%; }
.width65 { width: 65%; }
.width72 { width: 72%; }
.width90 { width: 90%; }
.width80 { width: 80%; }
.width85 { width: 85%; }
.width92 { width: 92%; }
.width95 { width: 95%; }

/*width in "px" */
.width45px { width: 45px; }
.width35px { width: 35px; }
.width310px { width:310px }
.width360px { width:360px }

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.vehicleList{
  margin-bottom: 5%
}

.ReactTable .rt-thead.-headerGroups{
  height: 37px;
}

.ReactTable .rt-thead.-header{
  height: 37px;
  background: rgba(0,0,0,0.03);
  box-shadow: 0 -3px 12px 0 rgba(0,0,0,0.15);
}

.ReactTable .rt-thead.-filters{
  border-bottom: 2px solid rgba(0,0,0,0.05);
  background: rgba(0,0,0,0.03);
}

.ReactTable .rt-thead .rt-resizable-header-content{
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}

.ReactTable .rt-tbody .rt-tr-group{
  border-bottom: 1px solid rgba(0,0,0,0.09);
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
}

div.-sort-desc::after {
    content: " \25BC";
    float: right;
    margin-top: -13px;
}

div.-sort-asc::after {
    content: " \25B2";
    float: right;
    margin-top: -13px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.dropdown-check-list {
  display: inline-block;
}
.dropdown-check-list .anchorOpen {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0px 50px 5px 10px;
}
.dropdown-check-list .anchorOpen:after {
  position: absolute;
  content: "";
  border-left: 2px solid ;
  border-top: 2px solid ;
  padding: 3px;
  right: 25px;
  top: 23%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.dropdown-check-list .anchorOpen:active:after {
  right: 25px;
  top: 21%;
}
.dropdown-check-list .anchorClose {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0px 50px 5px 10px;
}
.dropdown-check-list .anchorClose:after {
  position: absolute;
  content: "";
  border-right: 2px solid;
  border-bottom: 2px solid;
  padding: 3px;
  right: 25px;
  top: 35%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.dropdown-check-list .anchorClose:active:after {
  right: 25px;
  top: 21%;
}
.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.dropdown-check-list ul.items li {
  list-style: none;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(12px);
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.navbar-fixed-top{
  position: unset;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.footer-padding{
  padding-top: 140px
}

.gray-bb-focus:focus{
  border-bottom: 1px solid #9b9b9b !important;
}

.icon-size{
  font-size: 24px
}

.addGfType{
  width: 250px;
}

.addGfType1{
  width: 283px;
}

.addRouteType{
  width: 754px;
}

.addRouteType1{
  width: 754px;
}

.dashboardContElement{
  height: '700px';
  margin-left: "25%";
}

.newGfType{
  margin-top: -64px;
  height: 45px;
  margin-left: 45%;
  width: 250px;
}

.speedExceedsText {
  margin-top: 0%;
}

.savebtn {
  margin-top: 20%
}

.timeFormatInput {
  font-size: "24px";
  width:"50px";
  color:"#4a4a4a";
  text-align : center
}

.newGfType1{
  height: 45px;
  width: 283px;
  margin-left: 45%;
}
.border-bottom-only{
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  }

.white-dropdown :focus{
  background-color: #ffffff
}

.dropdown-veh-list .anchorOpen {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 10px 50px 8px 10px;
}
.dropdown-veh-list .anchorOpen:after {
  position: absolute;
  content: "";
  border-left: 2px solid;
  border-top: 2px solid ;
  padding: 3px;
  right: 25px;
  top: 30%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.dropdown-veh-list .anchorClose {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 10px 50px 8px 10px;
}
.dropdown-veh-list .anchorClose:after {
  position: absolute;
  content: "";
  border-right: 2px solid ;
  border-bottom: 2px solid ;
  padding: 3px;
  right: 25px;
  top: 43%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.veh-dropDown{
  /* bottom: 100%; */
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 900;
  display: none;
  float: left;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
table ,tbody{
  border: "1px solid transperent";
}

table td {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

table td:first-child {
  border-left: none;
}

table td:last-child {
  border-right: none;
}
.dropdown-width{
  width: 340px
}
.container-width{
  width:95% !important
}
.navbar > .container{
  width: auto;
}
.stats-icon{
  font-size: 18px
}
.stats-name{
  font-size: 16px
}
.stats-value{
  font-size: 18px
}
.td-width{
  width: 50%
}
.dropdown-menu {
  overflow-y: scroll;
  max-height: 550%;
}
.overflowY-auto{
  overflow-Y : auto
}
.overflowY-unset{
  overflow-Y : unset
}
.rotate-180deg{
  transform: rotate(180deg);
}
.dropdown-veh-list .anchorOpen {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 6px 50px 6px 10px;
}
.dropdown-veh-list .anchorOpen:after {
  position: absolute;
  content: "";
  border-left: 2px solid;
  border-top: 2px solid ;
  padding: 3px;
  right: 25px;
  top: 30%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.dropdown-veh-list .anchorClose {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 6px 50px 6px 10px;
}
.dropdown-veh-list .anchorClose:after {
  position: absolute;
  content: "";
  border-right: 2px solid ;
  border-bottom: 2px solid ;
  padding: 3px;
  right: 25px;
  top: 43%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.veh-dropDown{
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 900;
  display: none;
  float: left;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.verticalLine {
  border-left: thin solid #9b9b9b;
}

.overlay, .overlay-body { position: fixed; z-index: 9999;}
.overlay { top: 0; bottom: 0; left: 0; background: rgba(255,255,255,0.8); right: 0; }
.overlay-body { top: 50%; left: 50%; max-width: 250px; padding: 10px; transform: translate(-50%, -50%); z-index: 9999; border-radius: 10px; text-align: center; }
.overlay-body p { margin: 0 10px 10px; }
.ajjas-loaderImg { background: url('../images/loader.gif'); width: 50px; height: 50px; display: inline-block; margin: 0 auto; background-size: contain; }

.dropdown-veh-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.dropdown-veh-list ul.items li {
  list-style: none;
}
.date-bottom-padding {
  margin-bottom: 0%
}

.leftRight-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.stats-heading{
  font-size: 14px;
}
.stats-content{
  font-size: 18px;
}
/* .flatpicker-style .flatpickr-input{
  height: 41px;
  border-radius: 7px;
} */

/* Toolbar css starts from here */
.toolbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background:#f8f8f8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  height: 70px;
  z-index: 1000;
}
.toolbar_image {
  max-width: 100%;
  height: 45px;
}
.toolbar_logo{
  margin-left: 1rem;
}
.toolbar__navigation{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

/* Toolbar css end here */

/* Drawer Toggle Button css starts */
.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 27px;
  width: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 25px;
  height: 3px;
  background: #4a4a4a;

}
/*Drawer Toggle Button css ends*/

/*Drawer css starts*/
.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  max-width: 400px;
  z-index: 3000;
  transform: translateX(-102%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}
/*Drawer css ends*/

/* BackDrop css start */
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
  z-index: 2000;
}
/* BackDrop css end */

.profile_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}

.profile_pic{
background-color:#0094de;
border-radius:600px;
color:#ffffff;
display:inline-block;
font-size:25px;
font-weight:bold;
line-height:60px;
text-align:center;
text-decoration:none;
width:60px;
-webkit-text-size-adjust:none;
}

.user_name{
  margin-left: 15px;
  width: 60%;
  font-size: 18px;
}

.trips_btn{
  float: right;
}

.drawer_menu_name{
  font-weight: 300;
  /* font-size: 14px; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.14px;
}

.deviceName{
  font-size: 16px !important;
}
.device_info{
  font-size: 15px !important;
}

.page-survey__heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 21px;
  color: #212528;
  margin-bottom: 20px;
}
.panel__title {
  line-height: 1.5;
  letter-spacing: -0.17px;
  margin-bottom: 36px;
  font-size: 2.3rem;
}

.sf__label {
  font-size: 2rem;
  font-weight: 600;
  color: #383f44;
}

.rs__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

.rate-area {
  float: left;
  border-style: none;
  text-align: left;
  width: 60%;
  margin: 15px 0px;
}

.rate-area .rate-scale > button{
  cursor: pointer;
  flex:1;
  border-right: 1px solid #11111111;
  transition: background-color 0.3s ease;
}

.rate-scale{
  width: 100%;
  display: flex;
}

.rate-area-label{
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: Roboto;
}

.rate-area:not(:checked) > input {
  position: absolute;
  /*top: -9999px;*/
  clip: rect(0,0,0,0);
}

.rate-area:not(:checked) > label {
  float: right;
  width: 6vh;
  /* padding: 0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 5vh ;
  line-height: 1.2;
  color: lightgrey;
  text-shadow: 1px 1px #bbb;
}

.rate-area:not(:checked) > label:before { content: '★ '; }

.rate-area > input:checked ~ label {
  color: gold;
  text-shadow: 1px 1px #c60;
  font-size: 5vh !important;
}

.rate-area:not(:checked) > label:hover, .rate-area:not(:checked) > label:hover ~ label { color: gold; }

.rate-area > input:checked + label:hover, .rate-area > input:checked + label:hover ~ label, .rate-area > input:checked ~ label:hover, .rate-area > input:checked ~ label:hover ~ label, .rate-area > label:hover ~ input:checked ~ label {
  color: gold;
  text-shadow: 1px 1px goldenrod;
}

.rate-area > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.star_padding{
  padding-right: 1.8em;
}
/*   Media Queries   */
@media (min-width: 320px) and (max-width: 320px){
  .dropdown-width{
    width: 280px;
  }
   .star_padding{
    padding-right: 1em;
  }
}

@media (min-width: 321px) and (max-width: 500px){
  .star_padding{
    padding-right: 1.3em;
  }
}
@media (width: 360px) {
  .dropdown-width{
    width: 320px;
  }
}

@media (min-width: 411px) and (max-width: 415px) {
  .dropdown-width{
    width: 370px;
  }
  .newShipMent{
    margin-top: -2px;
    height: 45px;
    margin-left: 71%;
    width: 186px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .box {
    box-Shadow : none;
    height: 0px ;
    width: 100%;
  }
  .box-content{
    padding-left: 0%;
  }
  .box-padding{
    padding-left: 5%;
  }
  .vehicle-type{
    font-size: 8px;
  }
  .veh-form-title{
    font-size: 12px;
    padding-top: 0%
  }
  .form-padding{
    padding-top: 5%;
  }
  .footer-padding{
    padding-top: 50%
  }
  .veh-nm-margin{
    margin-top: -12%
  }
  .veh-no-margin{
    margin-top: -6%
  }
  .container-width{
    width: 90% !important;
  }
  .container{
    padding-left: 0px;
    padding-right: 0px;
  }
  .stats-icon{
    font-size: 12px
  }
  .stats-name{
    font-size: 12px
  }
  .stats-value{
    font-size: 16px
  }
  .td-width{
    width: 60%
  }
  .margin-bottom {
    margin-bottom: 12%
  }
  .margin-bottom11{
    margin-bottom: 10%
  }
  .notif {
    margin-top: 12%
  }
  .speedExceedsText {
    margin-top: 10%;
  }
  .savebtn {
    margin-top: 5%
  }
  .noNotif {
    margin-top: 15%
  }
  .vehicle-padding {
    margin-bottom: 3% !important
  }
  .routeName {
    height: 45px;
    width: 90%;
    margin-left: 45%;
  }
  .trips_btn{
    float: none;
  }
  .stats-heading{
    font-size: 13px;
  }
  .stats-content{
    font-size: 16px;
  }
  .deviceName{
    font-size: 14px !important;
  }
  .device_info{
    font-size: 13px !important;
  }
}
@media(max-width: 768px){
  .profile_dd{
    display: none;
  }

  .rate-area{
    width: 100%;
  }
}

@media(min-width: 769px){
  .drawer_btn{
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-header .collapse, .navbar-toggle {
    display:block !important;
  }
  .navbar-header {
    float: none;
  }
  .date-padding {
    margin-bottom: 3%
  }
}

@media (min-width: 425px) and (max-width: 1023px) {
  .veh-nm-margin{
    margin-top: -10%
  }
  .veh-no-margin{
    margin-top: -5%
  }
  .dashboardContElement{
    margin-left: '0px'
  }
  .speedExceedsText {
    margin-top: 10%;
  }
}

@media (min-width: 1024px) and (max-width: 2560px) {
  .hurray-info{ margin-top: 10%; }
  .hurray-btn{ margin-top: 2% }
  .veh-nm-margin{
    margin-top: -8%
  }
  .veh-no-margin{
    margin-top: -3%
  }
  /*.container {
    width: 1400px;
  }*/
  .speedExceedsText {
    margin-left: -50px
  }

}

@media (min-width: 1025px) and (max-width: 2560px) {
  .footer-padding{
    padding-top: 0
  }
  .veh-nm-margin{
    margin-top: -6%
  }
  .container {
    width: 1400px;
  }
  .login{
    height: 100vh;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .addRouteType{
    width: 681px;
  }

  .addRouteType1{
    width: 681px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .addRouteType{
    width: 532px;
  }

  .addRouteType1{
    width: 532px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .addRouteType{
    width: 285px;
  }
  .addRouteType1{
    width: 285px;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .addGfType{
    width: 210px;
  }
  .addGfType1{
    width: 245px;
  }
  .newGfType{
    margin-top: 20px;
    height: 45px;
    margin-left: 4%;
    width: 90%;
  }
  .newGfType1{
    width: 90%;
  }
  .newShipMent{
    margin-top: -2px;
    height: 45px;
    margin-left: 52%;
    width: 136px;
  }

}

@media(min-width: 320px) and (max-width: 325px)  {
  .flatpickr-input{
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .dailyRides{
    width : 100vh;
  }
  .container {
    width: 1400px;
  }
}
